import React, { useEffect, useState } from "react";
import { Card, Tabs, Input } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../DataLayer/axiosInstance";
import { Spin } from "antd";

const UserResponseDashboard = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [guidelinesData, setGuidelinesData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [userData, setUserData] = useState([]);
  const [recordSearchText, setRecordSearchText] = useState(null);
  const [filteredData, setFilteredDataSet] = useState([]);

  const { Search } = Input;

  useEffect(() => {
    renderData();
  }, []);

  const renderData = async () => {
    setLoading(true);
    try {
      const response = await Promise.all([
        getGuideLinesData(),
        getProcessData(),
        getPolicyData(),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGuideLinesData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/GuideLines/GetGuideLineDashboardData"
      );
      if (response?.status === 200) {
        setGuidelinesData(response?.data);
      } else {
        setGuidelinesData([]);
      }
    } catch (error) {
      console.log(error);
      setGuidelinesData([]);
    }
  };

  const getProcessData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/Process/GetProcessDashboardData"
      );
      if (response?.status === 200) {
        setProcessData(response?.data);
      } else {
        setProcessData([]);
      }
    } catch (error) {
      console.log(error);
      setProcessData([]);
    }
  };

  const getPolicyData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/Policy/GetPolicyDashboardData"
      );
      if (response?.status === 200) {
        setPolicyData(response?.data);
      } else {
        setPolicyData([]);
      }
    } catch (error) {
      console.log(error);
      setPolicyData([]);
    }
  };

  const getUserData = async (key, recordId) => {
    setLoading(true);
    try {
      let response = {};
      if (key === 1) {
        response = await axiosInstance.get(
          `/api/GuideLines/GetRespondedUsersById?id=${recordId}`
        );
      } else if (key === 2) {
        response = await axiosInstance.get(
          `/api/Policy/GetRespondedUsersById?id=${recordId}`
        );
      } else if (key === 3) {
        response = await axiosInstance.get(
          `/api/Process/GetRespondedUsersById?id=${recordId}`
        );
      } else {
        response = {};
      }
      if (response?.status === 200) {
        setUserData(response?.data);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  const renderSection = (key) => {
    if (key === 1) {
      return (
        <div>
          {/* <p>Guide Line Section</p> */}
          <div>{renderDataSection(key, guidelinesData)}</div>
        </div>
      );
    } else if (key === 2) {
      return (
        <div>
          {/* <p>Policy Section</p> */}
          <div>{renderDataSection(key, policyData)}</div>
        </div>
      );
    } else if (key === 3) {
      return (
        <div>
          {/* <p>Process Section</p> */}
          <div>{renderDataSection(key, processData)}</div>
        </div>
      );
    } else {
      return <p>Default Section</p>;
    }
  };

  const renderDataSection = (key, data) => {
    const handleSearch = (text) => {
      if (text) {
        const lowerSearchValue = text?.toLowerCase()?.trim();
        const filteredData = data.filter(
          (f) =>
            f?.name?.toLowerCase().includes(lowerSearchValue) ||
            f?.fileName?.toLowerCase().includes(lowerSearchValue)
        );
        setFilteredDataSet(filteredData);
      } else {
        setFilteredDataSet(data);
      }
    };

    return (
      <div className="flex-wrap">
        <div className="p-5 userResponse">
          <Card
            style={{
              minWidth: 300,
              maxWidth: 500,
              height: "calc(100vh - 200px)", // Adjust this value as per your requirement
              display: "flex",
              flexDirection: "column",
            }}
            bordered={true}
          >
            <Card.Meta
              title={
                <div className="flex-wrap align-i-c">
                  <p>Records : </p>
                  <Search
                    placeholder="Search by Name / FileName..."
                    onChange={(e) => {
                      setRecordSearchText(e.target.value);
                      handleSearch(e.target.value || "");
                    }}
                    style={{ width: "250px", height: "25px" }}
                    value={recordSearchText}
                  />
                </div>
              }
              description={
                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    scrollbarWidth: "thin",
                    overflowY: "auto",
                    flexGrow: 1,
                    paddingBottom: "10px",
                  }}
                >
                  {(recordSearchText ? filteredData : data)?.map((e) => {
                    return (
                      <div className="p-5 userResponse" key={`${key}+${e?.id}`}>
                        <Card
                          style={{
                            minWidth: 300,
                            maxWidth: 300,
                            backgroundColor:
                              selectedCard === `${key}+${e?.id}`
                                ? "#F0F8FF"
                                : null,
                          }}
                          bordered={true}
                          hoverable={true}
                          onClick={() => {
                            setSelectedCard(`${key}+${e?.id}`);
                            getUserData(key, e?.id);
                          }}
                        >
                          <div>
                            <span className="fs-10">Name : {e?.name}</span>
                            <br />
                            <span className="fs-10">
                              File Name : {e?.fileName}
                            </span>
                            <br />
                            <span className="fs-10">
                              User Response Count : {e?.userResponseCount || 0}
                            </span>
                          </div>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              }
            />
          </Card>
        </div>
        <div>
          <Card
            style={{
              minWidth: 300,
              height: "calc(100vh - 200px)", // Adjust this value as per your requirement
              display: "flex",
              flexDirection: "column",
            }}
            bordered={true}
          >
            <Card.Meta
              title="Users : "
              description={
                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    scrollbarWidth: "thin",
                    overflowY: "auto",
                    flexGrow: 1,
                    paddingBottom: "10px",
                  }}
                >
                  {userData.map((user, index) => {
                    return (
                      <p className="fs-10 fw-bold">{`${index + 1} . ${
                        user?.userEmail
                      }`}</p>
                    );
                  })}
                </div>
              }
            />
          </Card>
        </div>
      </div>
    );
  };

  const tabItems = [
    {
      key: 1,
      label: "Guide Line",
      children: renderSection(1),
    },
    {
      key: 2,
      label: "Policy",
      children: renderSection(2),
    },
    {
      key: 3,
      label: "Process",
      children: renderSection(3),
    },
  ];

  return (
    <div className="p-5">
      <Spin spinning={loading}>
        <div>
          <b>User Response</b>&nbsp;&nbsp;
          <ReloadOutlined
            style={{ color: "blue", cursor: "pointer" }}
            title="refresh"
            onClick={() => {
              renderData();
              setSelectedCard(null);
              setRecordSearchText(null);
              setUserData([]);
            }}
          />
        </div>
        {/* detailed section */}
        <div className="p-5">
          <Tabs
            defaultActiveKey={activeKey || 1}
            items={tabItems}
            onChange={(e) => {
              setActiveKey(1);
              setSelectedCard(null);
              setRecordSearchText(null);
              setUserData([]);
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default UserResponseDashboard;
