import React, { useEffect, useState } from "react";
import {
    LinkOrUnLinkGroupGuidelines,
    GetGroupMappedToGuidelines,
    GetGroupMappedToPolicy,
    LinkOrUnLinkGroupPolicy,
    GetGroupMappedToProcess,
    LinkOrUnLinkGroupProcess
} from "../Services/ConfigurationScreenApiServices";
import { Drawer, Button, Select } from "antd";

const ConfigurationScreenGroupsMap = ({ isOpen, componentName, configureId, groups, onClose }) => {
    const [exisingConfigurationScreenGroupIds, setExisingConfigurationScreenGroupIds] = useState([]);

    useEffect(() => {
        if (isOpen && configureId > 0) {
            getData();
        }
        return () => {
            setExisingConfigurationScreenGroupIds([]);
        };
    }, [configureId]);

    const getData = async () => {
        try {
            if (componentName === 'Guidelines') {
                const response = await GetGroupMappedToGuidelines(configureId);
                setExisingConfigurationScreenGroupIds(response);
            } else if (componentName === 'Policy') {
                const response = await GetGroupMappedToPolicy(configureId);
                setExisingConfigurationScreenGroupIds(response);
            } else if (componentName === 'Process') {
                const response = await GetGroupMappedToProcess(configureId);
                setExisingConfigurationScreenGroupIds(response);
            }
        } catch (error) { }
    };

    const handleSubmit = async () => {
        try {
            let data, response;

            if (componentName === 'Guidelines') {
                data = {
                    guideLineId: configureId,
                    groupIds: exisingConfigurationScreenGroupIds,
                };
                response = await LinkOrUnLinkGroupGuidelines(data);
            } else if (componentName === 'Policy') {
                data = {
                    policyId: configureId,
                    groupIds: exisingConfigurationScreenGroupIds,
                };
                response = await LinkOrUnLinkGroupPolicy(data);
            } else if (componentName === 'Process') {
                data = {
                    processId: configureId,
                    groupIds: exisingConfigurationScreenGroupIds,
                };
                response = await LinkOrUnLinkGroupProcess(data);
            }

            if (response?.status === 200) {
                onClose();
            } else {
                console.error('Something went wrong!');
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };


    return (
        <div>
            <Drawer
                title={componentName == 'Guidelines' ? "Map Group To Guidelines" : componentName == 'Policy' ? "Map Group To Policy" : componentName == 'Process' ? "Map Group To Process" : ""}
                width={400}
                onClose={(e) => {
                    onClose();
                }}
                open={isOpen}
                maskClosable={false}
                closable={false}
                footer={
                    <div style={{ textAlign: "right" }}>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            style={{ marginRight: 8 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleSubmit();
                            }}
                            type="primary"
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <Select
                    mode="multiple"
                    showSearch
                    style={{ width: "100%" }}
                    value={exisingConfigurationScreenGroupIds}
                    onChange={(val) => {
                        setExisingConfigurationScreenGroupIds(val);
                    }}
                    placeholder="Select Groups"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {groups?.map((group) => (
                        <Select.Option key={group?.id} value={group?.id}>
                            {group?.groupName}
                        </Select.Option>
                    ))}
                </Select>
            </Drawer>
        </div>
    );
};
export default ConfigurationScreenGroupsMap;
