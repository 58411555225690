import React, { useState, useEffect } from 'react';
import { Form, Button, Drawer, Input, Table, Select, Tag, Typography, Row, Col, Pagination, notification, Menu, Dropdown, Spin } from 'antd';
import { EditOutlined, ControlOutlined } from '@ant-design/icons';
import { baseUrl } from "../DataLayer/Constants";
import { axiosInstance } from '../DataLayer/axiosInstance';
// import { getGroupData, getApplicationsData } from '../DataLayer/CommonFunction';
import { useLoadingData } from '../DataLayer/CommonFunction';

const { Title } = Typography;
const { Search } = Input;

const ConfigureAccess = () => {
  const { loadingg, getGroupData, getApplicationsData, getApplicationData } = useLoadingData();
  const [groups, setGroups] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newConfiguration, setNewConfiguration] = useState({
    groupId: null,
    groupName: '',
    applications: []
  });
  const [searchText, setSearchText] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [flagFilter, setFlagFilter] = useState('0'); // Default to 'All'
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const listOfCalls = [1, 2, 3];
      const promiseResponse = await Promise.all(
        listOfCalls.map(async (e, index) => {
          if (e === 1) {
            return await getConfigureData();
          }
          if (e === 2) {
            const res1 = await getGroupData();
            setGroupData(res1);
            return res1;
          }
          if (e === 3) {
            const res2 = await getApplicationsData();
            setApplicationData(res2);
            return res2;
          }
        })
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getConfigureData = async () => {
    setLoading(true);
    let response = await axiosInstance.get('/api/Group/GetGroupApplications')
      .then(response => {
        setGroups(response.data);
        setFilteredGroups(response.data);
      })
      .catch(error => {
        console.error('Error fetching group data:', error.response || error.message);
      });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
 
  const paginatedApplications = filteredGroups?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const columns = [
    {
      title: 'S.No',
      key:"index",
      render:(value, item, index) => (currentPage - 1) * 10 + index + 1,
      width: 60,
    },
    {
      title: 'Groups',
      dataIndex: 'groupName',
      key: 'groupName',
      width: 100,
    },
    {
      title: 'Applications',
      dataIndex: 'applications',
      key: 'applications',
      width: 400,
      render: (applications) => (
        <>
          {applications?.map((app) => (
            <Tag color={app?.status ? "blue" : "red"} key={app?.applicationId || app?.id}>
              {app?.applicationName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 200,
      render: (isActive) => (isActive ? 'true' : 'false')
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        />
      ),
    },
  ];

  const handleEdit = (item) => {
    setSelectedGroup(item);
    setNewConfiguration({
      groupId: item?.id,
      groupName: item?.groupName,
      id: item?.id,
      isActive: item?.isActive,
      applications: item?.applications?.map(app => ({
        applicationId: app?.applicationId,
        applicationName: app?.applicationName
      }))
    });
    setIsDrawerOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedGroup(null);
    setNewConfiguration({
      groupId: null,
      groupName: '',
      applications: []
    });
    setIsDrawerOpen(true);
  };

  const handleSave = async () => {
    if (newConfiguration?.groupName?.trim() === '') {
      alert('Group name cannot be empty!');
      return;
    }

    const applicationIds = newConfiguration?.applications?.map(key => key?.applicationId);

    if (selectedGroup) {
      setLoading(true);
      // Update existing group
      let response = await axiosInstance.post('/api/Group/LinkOrUnLinkGroupApplications', {
        applicationIds,
        groupId: selectedGroup.id
      })
        .then(() => {
          const updatedGroups = groups?.map((group) => {
            if (group?.id === selectedGroup?.id) {
              if(group?.groupId === newConfiguration?.groupId) {
                let updatedApplications = newConfiguration?.applications?.map((application) => {
                  if (applicationIds.includes(application?.applicationId)) {
                    return { ...application, applicationName: application?.applicationName };
                  }
                  // return application;
                });
                let filterAry = updatedApplications?.filter(e => e != undefined);
                return {
                  ...group,
                  groupName: newConfiguration?.groupName,
                  applications: filterAry,
                };
              }
            }
            return group;
          });
          setGroups(updatedGroups);
          setFilteredGroups(updatedGroups);
          setIsDrawerOpen(false);
        })
        .catch(error => {
          console.error('Error updating group:', error.response || error.message);
        });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      // Create new group
      setLoading(true);
      let response = await axiosInstance.post('/api/Group/LinkOrUnLinkGroupApplications', {
        applicationIds: applicationIds,
        groupId: newConfiguration?.groupId
      })
        .then(response => {
          const newGroup = {
            id: newConfiguration?.groupId,
            groupName: newConfiguration?.groupName,
            groupId: newConfiguration?.groupId,
            applications: newConfiguration?.applications,
          };
          setGroups([...groups, newGroup]);
          setFilteredGroups([...filteredGroups, newGroup]);
          setIsDrawerOpen(false);
        })
        .catch(error => {
          console.error('Error creating group:', error.response || error.message);
        });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    fetchData();
  };

  const handleActivateApplications = async (isActive) => {
    const requestData = {
      groupIds: selectedRowKeys,
      isActive: isActive,
    };
    setLoading(true);

    try {
      const response = await axiosInstance.post('/api/Group/ActivateOrDeActivateApplicationGroup', requestData)
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: isActive
            ? 'Application activated successfully!'
            : 'Application deactivated successfully!',
        });
        setSelectedRowKeys([]);
        getConfigureData();
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the Application status. Please try again.',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleGroupChange = (groupName) => {
    const selectedGroup = groupData?.find(group => group?.groupName === groupName);

    setNewConfiguration({
      ...newConfiguration,
      groupId: selectedGroup?.id,
      groupName: selectedGroup?.groupName
    });
  };

  const handleApplicationChange = (selectedApplicationNames) => {
    const updatedApplications = selectedApplicationNames?.map(name => {
      const application = applicationData?.find(app => app?.applicationName === name);
      return application ? { applicationId: application?.id, applicationName: application?.applicationName } : null;
    }).filter(Boolean);

    setNewConfiguration({
      ...newConfiguration,
      applications: updatedApplications
    });
  };

  const handleFlagChange = (value) => {
    setFlagFilter(value);
    filterApplications(searchText, value);
  };

  const menu = (
    <Menu onClick={handleFlagChange}>
      <Menu.Item key="0">All</Menu.Item>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );

  const filterApplications = (searchValue, filterValue) => {
    const lowerSearchValue = searchValue?.toLowerCase();
    let filteredData = groups?.filter((group) =>
      group?.groupName.toLowerCase().includes(lowerSearchValue?.toLowerCase())
    );

    if (filterValue?.key === '1') {
      filteredData = filteredData?.filter(group => group?.isActive);
    } else if (filterValue?.key === '2') {
      filteredData = filteredData?.filter(group => !group?.isActive);
    }
    setFilteredGroups(filteredData);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    const newValue = event.target.value;
    setSearchText(newValue);
    filterApplications(newValue, flagFilter);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <div className="Container">
      <Spin spinning={loading}>
        <Row gutter={12} style={{ alignItems: 'center' }}>
          <Col xs={12} lg={21}>
            <Title level={4}>Configure Access</Title>
          </Col>
          <Col xs={12} lg={3}>
            <Button type="primary" className="AddOrUpdateButton" onClick={handleCreateNew}>
              Configure New
            </Button>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: 10 }}>
          <Col xs={2} lg={1}>
            <div className="icon-container">
              <Dropdown overlay={menu} trigger={['click']}>
                <ControlOutlined className="rotated-icon" />
              </Dropdown>
            </div>
          </Col>
          <Col xs={20} lg={16}>
            <Search
              placeholder="Search"
              style={{ width: '50%' }}
              value={searchText}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={12} lg={3}>
            {
              !selectedRowKeys || selectedRowKeys.length === 0 ? (
                <Button
                  danger
                  ghost
                  className='DeactivateButton'
                  disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                  onClick={() => handleActivateApplications(false)}
                >
                  Activate
                </Button>
              ) : (
                <Button
                  ghost
                  style={{
                    width: '100%',
                    fontSize: '12px',
                    height: '25px',
                    border: '1.5px solid green',
                    color: 'green',
                    padding: '0 10px',
                  }}
                  disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                  onClick={() => handleActivateApplications(true)}
                >
                  Activate
                </Button>
              )
            }
          </Col>
          <Col xs={12} lg={3}>
            <Button danger ghost
              className='DeactivateButton'
              disabled={!selectedRowKeys || selectedRowKeys.length === 0}
              onClick={() => handleActivateApplications(false)}
            >
              Deactivate
            </Button>
          </Col>
        </Row>
        <div className="TableContainer">
        <Table
          rowSelection={rowSelection}
          dataSource={paginatedApplications}
          columns={columns}
          size='small'
          rowKey="id"
          pagination={false}
        />
        </div>
        <Pagination align="end" current={currentPage} total={filteredGroups?.length} pageSize={pageSize} onChange={handlePageChange} onShowSizeChange={handlePageSizeChange}  pageSizeOptions={['10', '20', '50', '100', '200']}/>
        <Drawer
          title={selectedGroup ? 'Edit Configuration' : 'New Configuration'}
          width={400}
          open={isDrawerOpen}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button onClick={() => setIsDrawerOpen(false)} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button onClick={handleSave} type="primary">
                Save
              </Button>
            </div>
          }
          maskClosable={false}
          closable={false}
        >
          <Form layout="vertical">
            <Form.Item
              label="Group"
              style={{ marginBottom: 16 }}
            >
              <Select
                showSearch
                style={{ width: '100%', marginBottom: 16 }}
                value={newConfiguration?.groupName || undefined}
                onChange={handleGroupChange}
                placeholder="Enter group name"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {groupData?.map(group => (
                  <Select.Option key={group?.id} value={group?.groupName}>
                    {group?.groupName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Applications"
              style={{ marginBottom: 16 }}
            >
              <Select
                mode="multiple"
                showSearch
                style={{ width: '100%' }}
                value={newConfiguration?.applications?.map(app => app?.applicationName)}
                onChange={handleApplicationChange}
                placeholder="Select applications"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {applicationData?.map(app => (
                  <Select.Option key={app?.id} value={app?.applicationName}>
                    {app?.applicationName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Drawer>
      </Spin>
    </div>
  );
};

export default ConfigureAccess;
