import React, { useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Input,
  notification,
  Checkbox,
  List,
  Spin,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { axiosInstance } from "../DataLayer/axiosInstance";
import { base64ToBlob } from "../Services/ConfigurationScreenApiServices";

const CommonUserResponseSection = ({
  dataList,
  componentName,
  onSaveClick,
  processName,
}) => {
  const [dataSet, setDataSet] = useState(dataList);
  const [filteredDataSet, setFilteredDataSet] = useState(dataList);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [base64Data, setBase64Data] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileType, setFileType] = useState("");

  const { Search, TextArea } = Input;

  const handleSearch = (text) => {
    if (text) {
      const lowerSearchValue = text?.toLowerCase()?.trim();
      const filteredData = dataSet.filter(
        (f) =>
          f?.name?.toLowerCase().includes(lowerSearchValue) ||
          f?.description?.toLowerCase().includes(lowerSearchValue)
      );
      setFilteredDataSet(filteredData);
    } else {
      setFilteredDataSet(dataSet);
    }
  };

  const docViewerFunc = async (item) => {
    setLoading(true);
    notification.info({
      message: "Info",
      description: "File preview is in process...Please wait",
      duration: 1,
    });
    try {
      if (item) {
        let rowItemRes = null;

        // Fetch data based on componentName
        if (componentName === "guidelines") {
          rowItemRes = await axiosInstance.get(
            `/api/GuideLines/GetGuidelineById?id=${item.id}`
          );
        } else if (componentName === "policy") {
          rowItemRes = await axiosInstance.get(
            `/api/Policy/GetPolicyById?id=${item.id}`
          );
        } else if (componentName === "process") {
          rowItemRes = await axiosInstance.get(
            `/api/Process/GetProcessById?id=${item.id}`
          );
        }

        if (rowItemRes?.status === 200) {
          const base64Content = rowItemRes?.data?.fileContent;
          const mimeType = rowItemRes?.data?.fileType;

          const binaryString = base64ToBlob(base64Content, mimeType);
          const fileURL = URL.createObjectURL(binaryString);
          setBase64Data(fileURL);
          setFileType(mimeType);
          setIsModalVisible(true);
        } else {
          setBase64Data(null);
          setFileType("");
          setIsModalVisible(false);
        }
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "The uploaded document has Data error, Unable to view it",
      });
      setBase64Data(null);
      setFileType("");
      setIsModalVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const renderFileContent = () => {
    if (!base64Data) {
      return <Typography.Text>No file data available</Typography.Text>;
    }

    if (fileType === "application/pdf") {
      return (
        <iframe
          src={base64Data}
          title="Document Preview"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      );
    } else {
      return <Typography.Text>Unsupported file type</Typography.Text>;
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div>
          <Search
            placeholder="Search by Name / Description..."
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearchText(e.target.value || "");
            }}
            style={{ width: "250px", height: "25px" }}
            value={searchText}
          />
        </div>
        <div>
          {filteredDataSet?.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={filteredDataSet}
              style={{ padding: "30px" }}
              renderItem={(item, index) => (
                <>
                  <List.Item
                    actions={
                      !item?.isSavedData && item?.userResponseStatus
                        ? [
                            <Button
                              key={index}
                              onClick={() => {
                                onSaveClick(item, dataSet);
                              }}
                            >
                              save
                            </Button>,
                          ]
                        : []
                    }
                  >
                    <p style={{ marginRight: "25px" }}>
                      <b>{index + 1}.</b>
                    </p>
                    <List.Item.Meta
                      avatar={<FileTextOutlined />}
                      title={
                        <div>
                          <span>{item.name}</span>
                          <br />
                          <a
                            onClick={() => {
                              docViewerFunc(item);
                            }}
                          >
                            <span style={{ fontWeight: "normal" }}>
                              {item.fileName}
                            </span>
                          </a>
                          <br />
                          <span style={{ fontWeight: "normal" }}>
                            {item.description}
                          </span>
                        </div>
                      }
                      description={
                        <div>
                          {!item?.isSavedData ? (
                            <Checkbox
                              key={index}
                              checked={item?.userResponseStatus}
                              onChange={(ev) => {
                                const dataToUpdate = dataSet?.map((e) => {
                                  if (e?.id === item?.id) {
                                    e["userResponseStatus"] =
                                      ev?.target?.checked;
                                  }
                                  return e;
                                });
                                setDataSet(dataToUpdate);
                                setTimeout(() => {
                                  handleSearch(searchText);
                                }, 100);
                              }}
                            >
                              I have read the {processName} and I agree to the
                              terms and conditions.
                            </Checkbox>
                          ) : (
                            <span style={{ color: "green" }}>
                              This {processName} has been Accepted.
                            </span>
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                  <Modal
                    visible={isModalVisible}
                    title={item?.fileName}
                    footer={null}
                    onCancel={handleModalClose}
                    width="90%"
                    bodyStyle={{
                      height: "calc(105vh - 200px)",
                    }}
                    style={{ top: "50px" }}
                  >
                    {renderFileContent()}
                  </Modal>
                </>
              )}
            />
          ) : (
            <p style={{ textAlign: "center" }} className="p-5">
              No Records Found....
            </p>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default CommonUserResponseSection;
