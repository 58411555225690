import { axiosInstance } from "../DataLayer/axiosInstance";
import { toByteArray } from 'base64-js';


export const base64ToBlob = (base64Content, mimeType) => {
  const byteArray = toByteArray(base64Content);
  return new Blob([byteArray], { type: mimeType });
};

export const GetGroupMappedToGuidelines = async (configureId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.get(
        `/api/GuideLines/GetGroupMappedToGuidelines?id=${configureId}`,
        { headers }
      );
      if (response?.status === 200) {
        return response?.data;
      }
      return [];
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


  export const LinkOrUnLinkGroupGuidelines = async (data) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.post(
        "/api/GuideLines/LinOrUnLinkGroupGuideLines",
        data,
        { headers }
      );
      return response;
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


  export const GetGroupMappedToPolicy = async (configureId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.get(
        `/api/Policy/GetGroupMappedToPolicy?id=${configureId}`,
        { headers }
      );
      if (response?.status === 200) {
        return response?.data;
      }
      return [];
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


  export const LinkOrUnLinkGroupPolicy = async (data) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.post(
        "/api/Policy/LinOrUnLinkGroupPolicy",
        data,
        { headers }
      );
      return response;
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


  export const GetGroupMappedToProcess = async (configureId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.get(
        `/api/Process/GetGroupMappedToProcess?id=${configureId}`,
        { headers }
      );
      if (response?.status === 200) {
        return response?.data;
      }
      return [];
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


  export const LinkOrUnLinkGroupProcess = async (data) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axiosInstance.post(
        "/api/Process/LinOrUnLinkGroupProcess",
        data,
        { headers }
      );
      return response;
    } catch (error) {
      return "Something went wrong. Please try again...!";
    }
  };


