import React, { useState, useEffect } from 'react';
import CommonFile from '../CommonComponets/CommonFile';
import { Button, Typography, Row, Col, notification, Modal, Menu, Spin, Form } from 'antd';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { axiosInstance } from '../DataLayer/axiosInstance';
import ConfigurationScreenGroupsMap from './ConfigurationScreenGroupsMap';
import { base64ToBlob } from '../Services/ConfigurationScreenApiServices';


const ProcessConfiguration = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [flagFilter, setFlagFilter] = useState('0');
    const [componentName, setComponentName] = useState('Process');
    const [base64Data, setBase64Data] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');

    const [form] = Form.useForm();
    const { Title } = Typography;

    const [dataSource, setDataSource] = useState([]);
    const [copyState, setCopyState] = useState(dataSource);
    const [isMGGOpen, setIsMGGOpen] = useState(false);
    const configurationModal = {
        id: 0,
        name: "",
        description: "",
        isActive: true,
        isGlobal: true,
        createdBy: 0,
        updatedBy: 0,
    };
    const [selectedData, setSelectedData] = useState(configurationModal);
    const [groupData, setGroupData] = useState([]);


    useEffect(() => {
        renderData(true);
        getProcessData();
    }, []);

    const getProcessData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/api/Process/GetAllProcessForListing');
            setDataSource(response?.data);
            setCopyState(response?.data);
            setSearchText(null);
            setFlagFilter("0");
        } catch (error) {
            console.error('Error fetching group data:', error?.response || error?.message);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const renderData = async (e) => {
        setLoading(true);
        try {
            if (e) {
                const groupResponse = await axiosInstance.get("/api/Group/GetGroup");
                if (groupResponse?.status === 200) {
                    setGroupData(groupResponse?.data);
                } else {
                    setGroupData([]);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (e) {
                setGroupData([]);
            }
        }
    };

    const openDrawer = (record = null) => {
        setEditingRecord(record);
        setDrawerVisible(true);
        if (!record) {
            form.resetFields();
        }
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        form.resetFields();
    };

    const handleEdit = async (record) => {
        setLoading(true);
        try {
            if (record) {
                let rowItemRes = await axiosInstance.get(`/api/Process/GetProcessById?id=${record?.id}`);
                if (rowItemRes?.status === 200) {
                    console.log(dataSource);
                    const rowItemData = dataSource?.map(item =>
                        item.id === rowItemRes?.data?.id ? rowItemRes?.data : item
                    );
                    record["fileContent"] = rowItemRes?.data?.fileContent;
                    record["isActive"] = rowItemRes?.data?.isActive;
                    setDataSource(rowItemData);
                    setCopyState(rowItemData);
                }
            } else {
                setDataSource([]);
                setCopyState([]);
            }
        } catch (error) {
            console.error(error);
            setDataSource([]);
            setCopyState([]);
        } finally {
            setLoading(false);
        }
        setEditingRecord(record);
        setDrawerVisible(true);
    };

    const handleSave = async (values) => {
        setLoading(true);
        try {
            const isEditing = !!editingRecord;
            const payload = isEditing ? {
                id: isEditing ? editingRecord?.id || 0 : 0,
                name: values?.name,
                description: values?.description,
                fileName: values?.fileName || editingRecord?.fileName,
                fileSize: values?.fileSize || editingRecord?.fileSize,
                fileType: values?.fileType || editingRecord?.fileType,
                createdOn: isEditing ? editingRecord?.createdOn : new Date().toISOString(),
                updatedOn: new Date().toISOString(),
                isGlobal: values?.isGlobal,
                fileContent: values?.fileContent || editingRecord?.fileContent || null,
                isActive: isEditing ? editingRecord?.isActive || false : false,
            } : values;

            const response = await axiosInstance.post('/api/Process/AddOrUpdateProcess', payload);

            if (response.status === 200) {
                notification.success({
                    message: editingRecord ? 'Record Updated Successfully' : 'Record Created Successfully',
                });

                await getProcessData();
                setEditingRecord(null);
                closeDrawer();
            } else {
                throw new Error('Unexpected server response');
            }
        } catch (error) {
            notification.error({ message: 'Failed to save the record' });
        } finally {
            setLoading(false);
        }
    };

    const handleActivateGroups = async (isActive) => {
        const requestData = {
            ids: selectedRowKeys,
            isActive: isActive,
        };
        setLoading(true);
        try {
            const response = await axiosInstance.post('/api/Process/ActivateOrDeActivateProcess', requestData);
            if (response?.status === 200) {
                notification.success({
                    message: 'Success',
                    description: isActive
                        ? 'Process activated successfully!'
                        : 'Process deactivated successfully!',
                });
                const updatedGuideLines = dataSource.map(group => {
                    if (selectedRowKeys?.includes(group?.id)) {
                        return { ...group, isActive };
                    }
                    return group;
                });
                setDataSource(updatedGuideLines);
                setCopyState(updatedGuideLines);
                setSelectedRowKeys([]);
            }

        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the group status. Please try again.',
            });
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const rowItemDocViewFun = async (item) => {
        setLoading(true);
        notification.info({
            message: 'Info',
            description: 'File preview is in process...Please wait',
            duration: 1,
        });
        try {
            if (item) {
                const rowItemRes = await axiosInstance.get(`/api/Process/GetProcessById?id=${item.id}`);
                if (rowItemRes?.status === 200) {
                    const base64Content = rowItemRes?.data?.fileContent;
                    const mimeType = rowItemRes?.data?.fileType;
                    const fileName = rowItemRes?.data?.fileName;

                    const binaryString = base64ToBlob(base64Content, mimeType);
                    const fileURL = URL.createObjectURL(binaryString);
                    setBase64Data(fileURL);
                    setFileType(mimeType);
                    setFileName(fileName);
                    setIsModalVisible(true);
                } else {
                    setBase64Data(null);
                    setFileType('');
                    setFileName('');
                    setIsModalVisible(false);
                }
            }
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'The uploaded document has Data error, Unable to view it',
            });
            setBase64Data(null);
            setFileType('');
            setFileName('');
            setIsModalVisible(false);
        } finally {
            setLoading(false);
        }
    };

    const renderFileContent = () => {
        if (!base64Data) {
            return <Typography.Text>No file data available</Typography.Text>;
        }

        if (fileType === 'application/pdf') {
            return (
                <iframe
                    src={base64Data}
                    title="Document Preview"
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                    }}
                />
            );
        } else {
            return <Typography.Text>Unsupported file type</Typography.Text>;
        }
    };

    const groupColumns = [
        {
            title: 'S.No',
            key: "index",
            dataIndex: 'index',
            render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            render: (text, record) => text || 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 100,
            render: (text) => text || 'No Description',
        },
        {
            title: 'File Name',
            dataIndex: 'fileContent',
            key: 'fileContent',
            width: 100,
            render: (text, record) => (
                <a
                    onClick={() => {
                        rowItemDocViewFun(record);
                    }}
                >
                    {record?.fileName || 'FileContent'}
                </a>
            ),
        },
        {
            title: "IsGlobal",
            dataIndex: "isGlobal",
            key: "isGlobal",
            width: 100,
            render: (isGlobal) => (isGlobal ? "true" : "false"),
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 100,
            render: (isActive) => (isActive ? "true" : "false"),
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (text, record) => (
                <>
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} /> &nbsp;
                    <Button icon={<UsergroupAddOutlined />}
                        onClick={() => { setSelectedData(record); setIsMGGOpen(true); }}
                    />
                </>
            ),
        },
    ];

    const filterRowRecord = (searchValue, filterValue) => {
        const lowerSearchValue = searchValue?.toLowerCase();

        let filtered = dataSource?.filter(item =>
            item?.name?.includes(lowerSearchValue) ||
            (item?.description && item?.description.toLowerCase().includes(lowerSearchValue))
        );
        if (filterValue?.key === '1') {
            filtered = filtered?.filter(row => row?.isActive === true);
        } else if (filterValue?.key === '2') {
            filtered = filtered?.filter(row => row?.isActive === false);
        }
        setCopyState(filtered);
    };

    const handleFlagChange = (value) => {
        setFlagFilter(value);
        filterRowRecord(searchText, value);
    };

    const menu = (
        <Menu onClick={handleFlagChange} >
            <Menu.Item key="0">All</Menu.Item>
            <Menu.Item key="1">Active</Menu.Item>
            <Menu.Item key="2">Inactive</Menu.Item>
        </Menu>
    );

    return (
        <div className="Container">
            <Spin spinning={loading}>
                <Row gutter={12} style={{ alignItems: 'center' }}>
                    <Col xs={12} lg={21}>
                        <Title level={4}>Process</Title>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Button type="primary" className="AddOrUpdateButton" onClick={() => openDrawer()}>Create</Button>
                    </Col>
                </Row>

                <CommonFile
                    searchText={searchText} searchFun={(value) => {
                        setSearchText(value);
                        filterRowRecord(value, { key: flagFilter });
                    }}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedKeys) => {
                            setSelectedRowKeys(selectedKeys);
                        },
                    }}
                    getProcessData={getProcessData}
                    componentName={componentName}
                    isActiveFlagFun={handleActivateGroups}
                    menu={menu} columns={groupColumns} dataSource={copyState} visible={drawerVisible}
                    onClose={closeDrawer}
                    onSubmit={handleSave}
                    editingRecord={editingRecord}
                />
                <ConfigurationScreenGroupsMap
                    isOpen={isMGGOpen}
                    componentName={componentName}
                    configureId={selectedData?.id}
                    groups={groupData}
                    onClose={() => {
                        setIsMGGOpen(false);
                    }}
                />
            </Spin>
            <Modal
                visible={isModalVisible}
                title={fileName}
                footer={null}
                onCancel={handleModalClose}
                width="90%"
                bodyStyle={{
                    height: "calc(105vh - 200px)",
                    ...(fileType !== "application/pdf" && { overflow: "auto" })
                }}
                style={{ top: "50px" }}
            >
                {renderFileContent()}
            </Modal>
        </div>
    );
};

export default ProcessConfiguration;