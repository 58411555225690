import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "./Layout/Sidebar";
import Header from "./Layout/Header";
import Groups from "./Components/Groups";
import Applications from "./Components/Applications";
import Configurations from "./Components/ConfigureAccess";
import MyApplications from "./Components/MyApplications";
import DataSync from "./Components/DataSync";
import { authContext } from "./CommonComponets/adalConfig";
import axios from "axios";
import { baseUrl, adminRole } from "./DataLayer/Constants";
import Logs from "./Components/Logs";
import GenieConfigurations from "./Components/GenieConfigurations";
import AddEditGenie from "./Components/AddEditGenie";
import GuideLinesConfiguration from "./Components/GuideLinesConfiguration";
import PolicyConfiguration from "./Components/PolicyConfiguration";
import ProcessConfiguration from "./Components/ProcessConfiguration";
import Process from "./Components/UserResponseT&C/Process";
import Policy from "./Components/UserResponseT&C/Policy";
import GuideLines from "./Components/UserResponseT&C/GuideLines";
import UserResponseDashboard from "./Components/UserResponseDashboard";

const { Content } = Layout;

const App = ({ userRoles }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [userData, setUserData] = useState(authContext.getCachedUser());
  const [userProfilePhoto, setUserProfilePhoto] = useState(null);

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    getProfilePhoto();
  }, []);

  const getProfilePhoto = async () => {
    try {
      const userId = "3875475a-6666-4c8d-b50e-c568de474045";
      const response = await axios.get(
        baseUrl +
          `/api/User/GetUserProfilePhoto?userId=${userData?.profile?.oid}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      // Create a FileReader to convert the blob to Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setUserProfilePhoto(base64data);
      };
      reader.readAsDataURL(response.data);
      // const imageUrl = URL.createObjectURL(new Blob([response.data]));
      // setUserProfilePhoto(imageUrl);
      // console.log("Response:", response);
      // console.log("Image URL:", imageUrl);
    } catch (error) {
      console.log(
        "Error fetching profile photo:",
        error?.response ? error?.response?.data : error?.message
      );
      setUserProfilePhoto(null);
    }
  };

  return (
    <Router>
      <Layout style={{ height: "100vh" }}>
        <Header photo={userProfilePhoto} userRolesData={userRoles} />
        <Layout>
          <Sidebar onCollapse={handleCollapse} userRolesData={userRoles} />
          <Layout
            style={{
              marginLeft: collapsed ? 80 : 240, // Adjust margin based on collapsed state
              marginTop: 64, // Space for the fixed header
              transition: "margin-left 0.2s, margin-top 0.2s", // Smooth transition
            }}
          >
            <Content
              style={{
                background: "#fff",
                minHeight: 360,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/static/myApplications" />}
                />
                <Route
                  path="/static"
                  element={<Navigate to="/static/myApplications" />}
                />
                <Route
                  path="/static/myApplications"
                  element={<MyApplications />}
                />
                {userRoles?.userRole?.filter(
                  (f) =>
                    f["odataType"]?.includes("graph.directoryRole") &&
                    f?.displayName === adminRole
                )?.length > 0 ? (
                  <>
                    <Route path="/static/guideLines" element={<GuideLines />} />
                    <Route path="/static/policy" element={<Policy />} />
                    <Route path="/static/process" element={<Process />} />
                    <Route path="/static/Groups" element={<Groups />} />
                    <Route
                      path="/static/Applications"
                      element={<Applications />}
                    />
                    <Route
                      path="/static/Configurations"
                      element={<Configurations />}
                    />
                    <Route path="/static/dataSync" element={<DataSync />} />
                    <Route path="/static/Logs" element={<Logs />} />
                    <Route
                      path="/static/GenieConfigurations"
                      element={<GenieConfigurations />}
                    />
                    <Route
                      path="/static/GenieConfigurations/AddOrUpdate"
                      element={<AddEditGenie />}
                    />
                    <Route
                      path="/static/guideLineConfiguration"
                      element={<GuideLinesConfiguration />}
                    />
                    <Route
                      path="/static/policyConfiguration"
                      element={<PolicyConfiguration />}
                    />
                    <Route
                      path="/static/processConfiguration"
                      element={<ProcessConfiguration />}
                    />
                    <Route
                      path="/static/GenieConfigurations"
                      element={<GenieConfigurations />}
                    />
                    <Route
                      path="/static/GenieConfigurations/AddOrUpdate"
                      element={<AddEditGenie />}
                    />
                    <Route
                      path="/static/userResponseDashboard"
                      element={<UserResponseDashboard />}
                    />
                  </>
                ) : null}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
