import React, { useState, useEffect } from "react";
import CommonUserResponseSection from "../../CommonComponets/CommonUserResponseSection";
import { axiosInstance } from "../../DataLayer/axiosInstance";
import { Spin, notification } from "antd";

const Process = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [componentName, setComponentName] = useState("process");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/api/Process/GetAllProcessUserResponse"
      );
      if (response?.status === 200) {
        const flagAddedData = response?.data?.map((e) => {
          return { ...e, isSavedData: e?.userResponseStatus === true };
        });
        setData(flagAddedData);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  const onSaveClick = async (item, dataSet) => {
    setLoading(true);
    try {
      const dataToUpdate = {
        id: item?.id,
        status: item?.userResponseStatus,
      };
      const response = await axiosInstance.post(
        "/api/Process/AddUserProcessResponse",
        dataToUpdate
      );
      if (response?.status === 200) {
        notification.success({
          // message: "Success",
          description: "Response Captured Successfully.",
          duration: 1,
        });
        dataSet?.map((e) => {
          if (e?.id === item?.id) {
            e["isSavedData"] = true;
          }
          return e;
        });
        setData(dataSet);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="p-5">
      <Spin spinning={loading}>
        <p>
          <b>Process</b>
        </p>
        <div>
          {data?.length > 0 ? (
            <CommonUserResponseSection
              dataList={data}
              componentName={componentName}
              processName={"Process"}
              onSaveClick={(e, dataSet) => {
                onSaveClick(e, dataSet);
              }}
            />
          ) : (
            !loading && (
              <p style={{ textAlign: "center" }} className="p-5">
                No Records Found....
              </p>
            )
          )}
        </div>
      </Spin>
    </div>
  );
};
export default Process;
