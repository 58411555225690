import React, { useState, useEffect } from 'react';
import { Button, Input, Table, Checkbox, Row, Col, Pagination, message, Dropdown, Drawer, Form, Upload } from 'antd';
import { ControlOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';
const CommonFile = ({ visible, onClose, onSubmit, editingRecord, columns, dataSource, searchText, menu, rowSelection, searchFun, isActiveFlagFun, getGuideLinesData, getProcessData, getPolicyData,componentName }) => {
    const types = ["application/pdf"];
    const [fileList, setFileList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isFileValid, setIsFileValid] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editingRecord) {
            form.setFieldsValue({
                ...editingRecord,
                isGlobal: editingRecord?.isGlobal || false,
                name: editingRecord?.name,
                description: editingRecord?.description,
                file: editingRecord?.fileName
                    ? [
                        {
                            uid: '-1',
                            name: editingRecord?.fileName,
                            status: 'done',
                            url: editingRecord?.fileContent,
                            size: editingRecord?.fileSize,
                            type: editingRecord?.fileType,
                        },
                    ]
                    : [],
            });
        } else {
            setFileList([]);
            form.resetFields();
        }
    }, [editingRecord]);

    const handleFileUpload = ({ file, onSuccess, onError }) => {
        if (!types.includes(file?.type)) {
            message.error(`${file.name} is not a supported file type. Please upload a PDF format alone`);
            setIsFileValid(false);
            onError(new Error("Unsupported file type"));
            return;
        }

        setTimeout(() => {
            setFileList([{ ...file, status: 'done' }]);
            setIsFileValid(true);
            onSuccess("ok");
        }, 500);
    };

    const handleFileChange = (info) => {
        const { fileList } = info;
        if (info.file?.status === 'error') {
            setFileList(fileList.filter((file) => file.status !== 'removed'));
            setIsFileValid(false);
        }
    };

    const paginatedApplications = dataSource?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const getFileBase64Content = async (file) => {
        if (file?.originFileObj) {
            return await convertToBase64(file?.originFileObj);
        }
        return file?.url || null;
    };

    const handleFinish = async (values) => {
        const fileBase64Content = await getFileBase64Content(values?.file[0]);

        const record = {
            name: values?.name,
            description: values?.description,
            fileName: values?.file[0]?.name,
            fileType: values?.file[0]?.type,
            fileSize: values?.file[0]?.size,
            fileContent: fileBase64Content,
            isActive: values?.isActive || false,
            isGlobal: values?.isGlobal,
            createdOn: new Date()?.toISOString(),
            updatedOn: new Date()?.toISOString()
        };
        onSubmit(record);
        form.resetFields();
    };

    return (
        <><Row gutter={8} style={{ marginBottom: 10 }}>
            <Col xs={2} lg={1}>
                <div className="icon-container">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <ControlOutlined className="rotated-icon" />
                    </Dropdown>
                </div>
            </Col>
            <Col xs={20} lg={8}>
                <Input.Search
                    placeholder="Search"
                    style={{ width: '100%', height: '25px' }}
                    value={searchText}
                    onChange={(e) => searchFun(e.target.value)} />
            </Col>
            <Col xs={0} lg={8}></Col>
            <Col xs={1} lg={1}>
                <ReloadOutlined
                    style={{ color: "blue", cursor: "pointer", marginTop: "5px", }}
                    title="refresh"
                    onClick={() => {
                        searchFun(""); 
                        if (componentName === "Guidelines")
                              {getGuideLinesData();

                              }
                        else if (componentName ==="Process") {
                            getProcessData();
                        }
                        else if (componentName === "Policy") {
                            getPolicyData();}
                    }}
                />
            </Col>
            <Col xs={12} lg={3}>
                {
                    !rowSelection?.selectedRowKeys || rowSelection?.selectedRowKeys?.length === 0 ? (
                        <Button
                            danger
                            ghost
                            className='DeactivateButton'
                            disabled={!rowSelection?.selectedRowKeys || rowSelection?.selectedRowKeys?.length === 0}
                            onClick={() => isActiveFlagFun(false)}
                        >
                            Activate
                        </Button>
                    ) : (
                        <Button
                            ghost
                            style={{
                                width: '100%',
                                fontSize: '12px',
                                height: '25px',
                                border: '1.5px solid green',
                                color: 'green',
                                padding: '0 10px',
                            }}
                            disabled={!rowSelection?.selectedRowKeys || rowSelection?.selectedRowKeys?.length === 0}
                            onClick={() => isActiveFlagFun(true)}
                        >
                            Activate
                        </Button>
                    )
                }


            </Col>

            <Col xs={12} lg={3}>
                <Button danger ghost
                    className='DeactivateButton'
                    disabled={!rowSelection.selectedRowKeys || rowSelection.selectedRowKeys.length === 0}
                    onClick={() => isActiveFlagFun(false)}
                >
                    Deactivate
                </Button>
            </Col>
        </Row>
            <div className="TableContainer">
                <Table
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    size="small"
                    dataSource={paginatedApplications}
                    pagination={false}
                    scroll={{ y: 250 }} />
            </div>
            <Drawer
                title={editingRecord ? 'Edit Record' : 'Create Record'}
                visible={visible}
                onClose={onClose}
                destroyOnClose
            >
                <Form layout="vertical" form={form} onFinish={handleFinish}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter the name' }]}
                    >
                        <Input placeholder="Enter name" />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter the description' }]}
                    >
                        <Input placeholder="Enter description" />
                    </Form.Item>

                    <Form.Item name="isGlobal" valuePropName="checked">
                        <Checkbox>Is Global</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="file"
                        label="Upload File"
                        rules={[{ required: true, message: 'Please upload a file' }]}
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                    >
                        <Upload
                            accept=".pdf"
                            maxCount={1}
                            customRequest={handleFileUpload}
                            fileList={fileList}
                            onChange={handleFileChange}
                            showUploadList={{
                                showRemoveIcon: true,
                                showDownloadIcon: false,
                                renderFile: (file) => <span style={{ pointerEvents: 'none' }}>{file.name}</span>,
                            }}
                            beforeUpload={(file) => {
                                const isFileUnder2MB = file.size / 1024 / 1024 < 2;
                                if (!isFileUnder2MB) {
                                    message.error(`${file.name} is larger than 2 MB.`);
                                }
                                return isFileUnder2MB || Upload.LIST_IGNORE;
                            }}
                            onPreview={null}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}  disabled={!(isFileValid || (editingRecord && fileList.length === 0)) }>
                        {editingRecord ? 'Update' : 'Create'}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Form>
            </Drawer>
            <Pagination align="end" current={currentPage} total={dataSource?.length} pageSize={pageSize} onChange={handlePageChange} onShowSizeChange={handlePageSizeChange} pageSizeOptions={['10', '20', '50', '100', '200']} />
        </>

    )
};


export default CommonFile;
